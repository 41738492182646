* {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
    background: 0 0;
    font-weight: 400;
    font-style: normal;
    text-decoration: none;
    outline: 0;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="text"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 5px;
    background: 0 0;
    font-size: 14px;
}

input[type="button"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

input[type="number"] {
    -moz-appearance: textfield;
}

ol,
ul {
    list-style: none;
}

blockquote {
    quotes: none;
}

blockquote:after,
blockquote:before {
    content: "";
    content: none;
}

del {
    text-decoration: line-through;
}

address,
article,
aside,
footer,
header,
nav,
section {
    display: block;
    cursor: default;
}

body {
    font-family: "DM_sans";
    font-size: 17px;
    overflow-x: hidden;
    min-width: 320px;
    cursor: default;
}

body::-webkit-scrollbar {
    display: none;
}

input,
select,
textarea {
    font-family: "DM_sans";
}

/* html {
  overflow: scroll !important;
  scroll-behavior: smooth !important;
  scrollbar-width: none !important;
} */

html.modal-enabled {
    overflow: hidden !important;
}

a {
    color: #444;
    cursor: pointer;
}

.submit:hover,
a:hover {
    -moz-opacity: 0.85;
    -khtml-opacity: 0.85;
    opacity: 0.85;
}

.thumb {
    display: block;
}

.thumb img {
    display: block;
    width: 100%;
}

p {
    line-height: 1.6em;
    font-size: 14px;
    color: #818181;
    font-family: "DM_sans";
}

.hidden {
    display: none;
}

br.clear {
    clear: both;
    line-height: 0;
}

.bold {
    font-family: "DM_sans_medium";
}

.e-t-h {
    margin-bottom: 25px;
}

.e-t-h2 {
    font-size: 37px;
    color: #444;
    font-family: "DM_sans_medium";
    letter-spacing: 0.02rem;
}

.b-regular {
    font-family: "DM_sans_bold";
}

.b-medium {
    font-family: "DM_sans_medium";
}

.b-semi {
    font-family: "DM_sans";
}

.white {
    color: #fff;
}

.black h5 {
    color: #000;
    font-size: 18px;
}

a,
input {
    font-family: "DM_sans";
    color: #000;
    font-weight: 400;
    font-size: 16px;
}

input::-webkit-input-placeholder {
    color: #9b9a9a;
}

input:-moz-placeholder {
    color: #9b9a9a;
}
h1,
h2 {
    font-family: "DM_Sans_bold";
    color: #000;
    font-weight: 700;
}
.blocked-input {
    pointer-events: none;
}
.image {
    width: 100%;
    display: block;
}
@font-face {
    font-display: auto;
    font-family: "DM_sans";
    src: url(../fonts/DMSans-Regular.woff) format("woff");
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-display: auto;
    font-family: "DM_sans_medium";
    src: url(../fonts/DMSans-Medium.woff) format("woff");
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-display: auto;
    font-family: "DM_sans_bold";
    src: url(../fonts/DMSans-Bold.woff) format("woff");
    font-weight: 700;
    font-style: normal;
}

@keyframes slideInRight {
    50% {
        transform: translateX(50%);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes slideInLeft {
    50% {
        transform: translateX(-50%);
    }
    100% {
        transform: translateX(0);
    }
}

.transition-right {
    animation: slideInRight 1s ease;
}

.transition-left {
    animation: slideInLeft 1s ease;
}

#SvgjsTspan1246,
#SvgjsTspan1249,
#SvgjsTspan1252,
#SvgjsLine1208,
#SvgjsLine1209 #SvgjsLine1216,
#SvgjsLine1207,
#SvgjsLine1216,
#SvgjsLine1209,
#SvgjsLine1211,
#SvgjsLine1212,
#SvgjsLine1210,
#SvgjsG1198,
.apexcharts-menu-icon,
.apexcharts-data-labels {
    display: none !important;
}
.container {
    width: 100%;
    justify-content: space-between;
}
.otp-input {
    background: #f4f4f4;
    border-radius: 8px;
    width: 24% !important;
    padding: 17px 12px;
    border: 2px solid transparent;
    font-size: 16px;
    font-family: "DM_sans_medium";
}
.otp-input:focus {
    border: 2px solid #366ee3;
}
.login-otp-input {
    background: #f4f4f4;
    width: 23.5% !important;
    padding: 12px 24px;
    border: 2px solid transparent;
    border-radius: 25px;
    font-size: 16px;
    font-family: "DM_sans_medium";
    color: #3e3e53;
}
.login-otp-input:focus {
    border: 2px solid #366ee3;
    background: #fff;
    box-shadow: 0px 0px 0px 2px rgba(43, 57, 144, 0.2);
}
html.modal-enabled {
    overflow: hidden !important;
}
@keyframes scroll {
    from {
        background-position: 0 0;
    }
    to {
        background-position: 0 -1000px;
    }
}
.date-picker-wrapper {
    position: relative;
}

.date-picker-wrapper .react-datepicker {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 9999;
}
.weekend {
    color: red !important;
}
.red {
    color: red;
}
.rdp-cell:first-child,
.rdp-head_cell:first-child {
    padding-left: 10px;
}
.rdp-head_cell:last-child {
    padding-right: 10px;
}

.rdp-cell:last-child {
    color: red !important;
    padding-right: 10px;
}
.rdp-cell:nth-child(6),
.rdp-head_cell:last-child,
.rdp-head_cell:nth-child(6) {
    color: red !important;
}
.rdp-cell {
    font-size: 16px;
    font-family: "DM_sans";
}
.rdp-day_selected,
.rdp-day_selected:focus-visible,
.rdp-day_selected:hover {
    background: #e0f8f2 !important;
    color: #000 !important;
}
.rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
    background: #e0f8f2 !important;
}
.rdp-caption_label {
    font-size: 16px !important;
    font-family: "DM_sans_medium" !important ;
    font-weight: 500 !important;
}
.rdp-nav_icon {
    width: 24px !important;
}
.available {
    color: red;
    padding: 2px;
    border-radius: 50%;
}
.login-otp {
    border: 2px solid red;
    background: #fff;
    box-shadow: 0px 0px 0px 2px rgba(43, 57, 144, 0.2);
    background: #f4f4f4;
    width: 23.5% !important;
    padding: 12px 24px;
    border-radius: 25px;
    font-size: 16px;
    font-family: "DM_sans_medium";
    color: #3e3e53;
}

/*  */
/* .wrapper {
    width: 85%;
    margin: 0 auto;
}

.inner-wrapper {
    width: 70%;
    margin: 0 auto;
}

@media all and (max-width: 1280px) {
    .inner-wrapper {
        width: 80%;
    }
}
@media all and (max-width: 1080px) {
    .wrapper {
        width: 80%;
    }
}

@media all and (max-width: 640px) {
    .inner-wrapper {
        width: 85%;
    }

    .wrapper {
        width: 90%;
    }
}

@media all and (max-width: 480px) {
    .inner-wrapper {
        width: 90%;
    }
    p {
        font-size: 14px;
    }
} */

/* PAGINATION */

/* .pagination {
    display: flex;
    justify-content: center;
    cursor: pointer;
    margin-top: 30px;
    flex-wrap: wrap;
}

.pagination a {
    border: 1px solid rgb(15, 167, 111);
    width: 40px;
    height: 40px;
    display: flex;
    font-family: "DM_sans_medium";
    font-size: 15px;
    padding: 10px;
    border-radius: 50%;
    color: rgb(15, 167, 111);
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    cursor: pointer;
}

@media all and (max-width: 480px) {
    .pagination a {
        padding: 4px 12px;
    }
}

.pagination li {
    margin-right: 15px;
}

.pagination li.break a {
    display: inline-block;
}

.previous .pagination__link {
    background-color: unset;
    color: rgb(15, 167, 111);
    font-family: "DM_sans_medium";
}

.next .pagination__link {
    background-color: unset;
    color: rgb(15, 167, 111);
    font-family: "DM_sans_medium";
}

.pagination__link {
    font-weight: bold;
}

.pagination__link--active a {
    color: #fff;
    background: rgb(15, 167, 111);
}

.pagination__link--disabled a {
    cursor: not-allowed;
    display: none;
}

.active_link_classname {
    color: #fff;
    background: rgb(15, 167, 111);
}
.count {
    color: #f4f4f4;
    font-size: 16px;
    margin-right: 5px;
    font-family: "DM_sans_medium";
} */
/* PAGINATION ENDS*/

/* .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 0px 10px !important;
}
.css-1xhypcz-MuiStack-root {
  overflow: hidden !important;
  padding-top: 0px;
}
.css-1xhypcz-MuiStack-root {
  padding-top: 0px !important;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  padding-right: 0px !important;
  width: 265px !important;
}
.css-i4bv87-MuiSvgIcon-root {
  padding-right: 4px !important;
} */
/* ._1aDvk {
  display: none;
}
._3curk {
  background-color: transparent !important;
  position: relative;
}
._3cxVN {
  position: absolute;
  right: 506px;
  bottom: 68px;
  border: 1px solid red !important;
}
._yof3Q select {
  background-color: #fff !important;
} */
